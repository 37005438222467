import React,{useState,useEffect} from 'react'
import axios from 'axios';
import FileUploadDesign from '../../../components/common/FileUploadDesign';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {MenuItem, Switch } from '@mui/material';
import {Button} from '@mui/material';
import Iconify from '../../../components/Iconify';
import palette from '../../../theme/palette';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { uploadFileToFirebase } from 'src/global/globalFunctions';
import CircularProgress from '@mui/material/CircularProgress';
import CustomizedSnackbars from '../../../global/Snackbar/CustomSnackbar';
import noImage from '../../../assests/No_image.svg'
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import CancelIcon from '@mui/icons-material/Cancel';
import pdfImage from "../../../assests/pdf.png"



function AddProducts({handleClose}) {
   const [productData , setProductData ] = useState({product_main_category:'choose_brand',product_category:'choose_category',product_subcategory:'choose_sub_category',product_subject_category:'choose_subject_category'})
   const [ mainCategory,setMainCategory ] = useState([]);
   const [ category,setCategory ] = useState([]);
   const [ subCategory,setSubCategory ] = useState([]);
   const [message ,setMessage] = useState({type:"",message:""})
   const [ loading, setLoading  ] =useState(false)
   const [fileUpload , setFileUpload ] = useState()
   const [snackbarOpen,setSnackbarOpen ] = useState(false)
   const [ render, setRender ] = useState(false)
   const [checked, setChecked] = React.useState(true);
   const [pdfFIleField, setPdfFIleField] = useState([{ pdf_file_title: "",paid:false,file:null}])
   const [youtubeFIleField, setYoutubeFIleField] = useState([{ youtube_video_title: "",youtube_video_id:''}])
   console.log("PRODUCT DATA",productData)

       //================= GET ALL MAIN CATEGORY =================
  
const subjectCate=[
  'physics',
  'chemistry',
  'biology',
  'history',
  'geography',
  'civics',
  'economics',
  'grammer',
  'literature',
  'व्याकरण',
  'साहित्य',
]




  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/api/get/addproduct/maincategory`, { withCredentials: true })
    .then((res) => {
      console.log(res);
      setMainCategory(res?.data);
    })
    .catch((err) => {
      console.log(err);
    });
  }, []);
  //================= GET ALL MAIN CATEGORY =================

  // GET CATEGORY BY BRAND
  useEffect(()=>{
    if(productData?.product_main_category === 'choose_brand')return;
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/get/category/for/addproduct?main_category=${productData?.product_main_category}`)
    .then(res=>{
      console.log(res)
        setCategory(res?.data)
    })
  },[productData?.product_main_category])
  // GET CATEGORY BY BRAND

  // GET SUB CATEGORY BY BRAND
  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/get/category/for/addproduct?category=${productData?.product_category}`)
    .then(res=>{
      console.log(res)
      setSubCategory(res?.data[0]?.subcategory)
    })
  },[productData?.product_category])
  // GET SUB CATEGORY BY BRAND


    const handleChange=(e)=>{
        setProductData((prev)=>({...prev,[e.target.name]:e.target.value}))
    }
    
    // File upload function 
    const handleFileUpload = (e)=>{
      if(e.target.files?.length > 4) return alert("You can only select 4 images")
      console.log(e.target.files)
      let allImages = [...e.target.files]
      setFileUpload(allImages)

    }
    console.log(fileUpload)

    // remove image after select
    const handleRemoveImage=(removeByIndex)=>{
      console.log(removeByIndex)
      const afterRemove= fileUpload?.filter((value,index)=>{
        return index !=removeByIndex
      })
      console.log("AFTER REMOVE IMAGE=>",afterRemove )
      setFileUpload(afterRemove)
    }

    // handle form submit 
    const handleSubmit = async(e)=>{
      e.preventDefault()
     
      // if(!fileUpload){
      //   alert('Add Atleast 1 PDF !!');
      //   return
      // }

      setLoading(true);
      let pdfFileToFirebase=[];
      console.log("pdfFileToFirebase---->>",pdfFileToFirebase)
      if(pdfFIleField?.length ){
        for(let i = 0; i<pdfFIleField?.length;i++){
          console.log("dfFIleField?.file[i]---->>",pdfFIleField[i]?.file)

          // pdfFileToFirebase[i] = await uploadFileToFirebase(`/ssastore/products/${productData?.product_code}/`,pdfFIleField[i]?.file);
          pdfFileToFirebase[i] ={
                                pdf_file_title:pdfFIleField[i]?.pdf_file_title,
                                paid:pdfFIleField[i]?.paid,
                                image:await uploadFileToFirebase(`/ssastore/products/${productData?.product_code}/`,pdfFIleField[i]?.file)
                     }

        }
       }
     await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/add/new/product`,{...productData,youtube_file:youtubeFIleField,pdf_files:pdfFileToFirebase},{withCredential:true})
      .then(res=>{
          console.log(res)
          setLoading(false);
          setMessage((prev)=>({...prev,type:'success',message:'Content Added Successfully !!'}))
          setSnackbarOpen(true);
          setProductData({
          product_main_category:'choose_brand',
          product_category:'choose_category',
          product_subcategory:'choose_sub_category',
          product_subject_category:'choose_subject_category',
          product_code:'',
          product_name:'',
          product_variant:'',
          product_quantity:'',
          product_description:'',
          color:'',
          size:'',
          cartoon_total_products:''

        });
        setPdfFIleField([{ pdf_file_title: "",paid:false,file:null}])
   setYoutubeFIleField([{ youtube_video_title: "",youtube_video_id:''}])
          setFileUpload([])
      })
      .catch(err=>{
          console.log(err);
          setLoading(false);
      })
      setLoading(false);
  }
   

// ##################### SNACK BAR FUNCTIONs ##################
const handleCloseSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }

  setSnackbarOpen(false);
};
// ##################### SNACK BAR FUNCTIONs ##################

// for pdf fields

const handleChangeSwitch =(i,event)=>{
  let newFormValues = [...pdfFIleField];
  newFormValues[i]['paid'] = event.target.checked;
  setPdfFIleField(newFormValues);
};


let addFormFields = () => {
  setPdfFIleField([...pdfFIleField, { pdf_file_title: ""}])
}

let removeFormFields = (i) => {
  let newFormValues = [...pdfFIleField];
  newFormValues.splice(i, 1);
  setPdfFIleField(newFormValues)
}

let handleChangePdfName = (i, e) => {
  let newFormValues = [...pdfFIleField];
  newFormValues[i][e.target.name] = e.target.value;
  setPdfFIleField(newFormValues);
}

const handlePdfFileChange =async(i, e) => {
  const pdfFile = e.target.files[0]
  let addFileToPDF = [...pdfFIleField];
    addFileToPDF[i]['file'] = pdfFile;
    setPdfFIleField(addFileToPDF);
  //  const result=await uploadFileToFirebase("/ssastore/",fileUpload)
  // console.log("RESUL AFTER UPLOADED :",result)
};


console.log("RESUL AFTER UPLOADED :",pdfFIleField)


// for youtube fields
const handleChangeSwitchYoutube =(i,event)=>{
  let newFormValues = [...youtubeFIleField];
  newFormValues[i]['paid'] = event.target.checked;
  setYoutubeFIleField(newFormValues);
};


let addFormFieldsYoutube = () => {
  setYoutubeFIleField([...youtubeFIleField, { youtube_video_title: ""}])
}

let removeFormFieldsYoutube = (i) => {
  let newFormValues = [...youtubeFIleField];
  newFormValues.splice(i, 1);
  setYoutubeFIleField(newFormValues)
}

let handleChangeYoutubeName = (i, e) => {
  let newFormValues = [...youtubeFIleField];
  newFormValues[i][e.target.name] = e.target.value;
  setYoutubeFIleField(newFormValues);
}

const handleYoutubeFileChange =async(i, e) => {
  const pdfFile = e.target.files[0]
  let addFileToPDF = [...youtubeFIleField];
    addFileToPDF[i][e.target.name] = pdfFile;
    setYoutubeFIleField(addFileToPDF);
  //  const result=await uploadFileToFirebase("/ssastore/",fileUpload)
  // console.log("RESUL AFTER UPLOADED :",result)
};




// console.log("pdfFIleFieldpdfFIleFieldpdfFIleField",pdfFIleField)

  return (
    <>
      {/* #################### LOADING SPINNER ######################## */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    {/* #################### LOADING SPINNER ######################## */}
    {/* #################### SANCKBAR MESSAGE ######################## */}
    <CustomizedSnackbars onOpen={snackbarOpen} type={message?.type} handleClose={handleCloseSnackbar}  message={message?.message} />
 
    {/* #################### SANCKBAR MESSAGE ######################## */}
        <div className='product-conatiner'>
        <div className='addproducts_slider'>
            <div className='slider_heading'>
                <h4>Add Course Content</h4>
                <p>Add your Course Content and necessary information from here</p>
            </div>
            <div className='close_edit_Category ' >
    <HighlightOffIcon style={{color:'red'}} onKeyDown={handleClose}  onClick={handleClose} fontSize='large' />
    {/* <HighlightOffIcon style={{color:palette.primary.main}}  fontSize='large' /> */}
</div>
            <div className='addproduct_img_and_details flex'>


            <div className='add_product_form'>
                    <form onSubmit={handleSubmit} >
                    <div className='flex' style={{width:'100%',gap:'10px'}} >
                    <div className='add_product_label_input' style={{width:'100%'}} >
                    <label htmlFor="">Chapter No.  </label>
                    <TextField required fullWidth className='product_form_input' id="outlined-basic" name="product_name" value={productData?.product_name} onChange={handleChange} placeholder="Chapter No. " variant="outlined" />
                    </div>

                    <div className='add_product_label_input'  style={{width:'100%'}} >
                    <label htmlFor="">Code  </label>
                    <TextField required fullWidth className='product_form_input' id="outlined-basic" name="product_code" value={productData?.product_code} onChange={handleChange} placeholder="Code " variant="outlined" />
                    </div>
                    </div>

                 
                 

                 

                    <div className='add_product_label_input'>
                    <label htmlFor=""> Select Course  </label>
                    <TextField  labelId="demo-select-small" id="demo-select-small"
                     className='select_field' name="product_main_category" style={{textTransform:'capitalize'}} value={productData?.product_main_category} onChange={handleChange} 
                       select 
                       SelectProps={{
                        isNative:true,
                        MenuProps:{PaperProps: {
                          style: {
                            maxHeight: 250,
                            width: 250,
                          },
                        },}
                      }
                      }
                        >
                    <MenuItem value='choose_brand' disabled >Choose Course</MenuItem>
                    {mainCategory?.map((value,index)=>(
                        <MenuItem key={value._id} style={{textTransform:'capitalize'}} value={value?._id}>{value?._id}</MenuItem>

                    ))}
                    </TextField>
                    </div>
                    <div className='add_product_label_input'>
                    <label htmlFor=""> Select Class  </label>
                    <TextField style={{textTransform:'capitalize'}} labelId="demo-select-small" id="demo-select-small" className='select_field'
                     name='product_category' value={productData?.product_category} onChange={handleChange} 
                       select
                       SelectProps={{
                        isNative:true,
                        MenuProps:{PaperProps: {
                          style: {
                            maxHeight: 250,
                            width: 250,
                          },
                        },}
                      }
                      }
                       >
                    <MenuItem value='choose_category' disabled >Choose Class</MenuItem>
                    {category?.map((value,index)=>(
                      <MenuItem key={value?._id} style={{textTransform:'capitalize'}} value={value?.category_name}>{value?.category_name}</MenuItem>

                    ))}
                    </TextField>
                    </div>

                    

                    <div className='add_product_label_input'>
                    <label htmlFor=""> Select Subject  </label>
                    <TextField style={{textTransform:'capitalize'}} labelId="demo-select-small" id="demo-select-small" className='select_field'
                     name='product_subcategory' value={productData?.product_subcategory} onChange={handleChange}  
                      select
                      SelectProps={{
                        isNative:true,
                        MenuProps:{PaperProps: {
                          style: {
                            maxHeight: 250,
                            width: 250,
                          },
                        },}
                      }
                      }
                      >
                    <MenuItem value='choose_sub_category'>Choose Subject  </MenuItem>
                    {subCategory?.map((value,index)=>(
                      <MenuItem key={value?._id} style={{textTransform:'capitalize'}} value={value?.name}>{value?.name}</MenuItem>

                    ))}
                    </TextField>
                    </div>
                    <div className='add_product_label_input'>
                    <label htmlFor=""> Select Subject Category </label>
                    <TextField style={{textTransform:'capitalize'}} labelId="demo-select-small" id="demo-select-small" className='select_field'
                     name='product_subject_category' value={productData?.product_subject_category} onChange={handleChange}  
                      select
                      SelectProps={{
                        isNative:true,
                        MenuProps:{PaperProps: {
                          style: {
                            maxHeight: 250,
                            width: 250,
                          },
                        },}
                      }
                      }
                      >
                    <MenuItem value='choose_subject_category' disabled >Choose Subject Category </MenuItem>
                    {subjectCate?.map((value,index)=>(
                      <MenuItem key={value} style={{textTransform:'capitalize'}} value={value}>{value}</MenuItem>

                    ))}
                    </TextField>
                    </div>
                    {/* <div className='add_product_label_input'>
                    <label htmlFor="">Content Type  </label>
                    <TextField style={{textTransform:'capitalize'}} labelId="demo-select-small" id="demo-select-small" className='select_field'
                     name='product_subcategory' value='free' onChange={handleChange}  
                      select
                      SelectProps={{
                        isNative:true,
                        MenuProps:{PaperProps: {
                          style: {
                            maxHeight: 250,
                            width: 250,
                          },
                        },}
                      }
                      }
                      >
                    <MenuItem value='choose_sub_category'>Content Type  </MenuItem>
                    <MenuItem style={{textTransform:'capitalize'}} value='free'>Free</MenuItem>
                      <MenuItem style={{textTransform:'capitalize'}} value='paid'>Paid</MenuItem>

                    </TextField>
                    </div> */}

                    <div className='add_product_label_input'>
                    <label htmlFor=""> Description  </label>
                    <TextField multiline rows={5}  fullWidth className='product_form_input' name='product_description' value={productData?.product_description} onChange={handleChange} id="outlined-basic" placeholder=" Add Description " variant="outlined" />
                    </div>

                  <div style={{paddingTop:20}} >

                    <Button  variant='outlined' style={{marginRight:"10px"}} onClick={handleClose}  startIcon={<Iconify icon="akar-icons:arrow-back" />} > Go Back  </Button>
                
                     <Button   variant='contained' type='submit' style={{padding:"6px 30px"}} startIcon={<Iconify icon="ant-design:plus-outlined" />} > Save Changes </Button>

                    </div>
                    </form>
                    
                </div>



{/* PDF FILE COL */}
                <div className='file_upload_col'>
            <h4>High Quality PDF Notes</h4>
                    {/* <FileUploadDesign fileUpload={fileUpload} handleFileUpload={handleFileUpload} /> */}
                    {pdfFIleField.map((element,index)=>(
                      <div key={index} >
                    <div className='add_product_label_input' style={{width:'100%'}} >
                    <label htmlFor="">PDF {index +1 }  </label>
                    {
                index ? 
                  
                  <Button variant='text' style={{marginLeft:"16px"}} onClick={() => removeFormFields(index)} > Remove </Button>
                : null
              }
                   {/* <TextField required fullWidth id="outlined-basic" name='subcategory'  placeholder="Subject Name" variant="outlined" /> */}

                    <TextField  required fullWidth className='product_form_input' id="outlined-basic" value={element.pdf_file_title || ""} onChange={e => handleChangePdfName(index, e)}  name="pdf_file_title"  placeholder="Enter File Title" variant="outlined" />
                    </div>

                   <div className='flex-justify-between' style={{marginTop:18}} >
                    <div style={{paddingRight:12}} >
                    <Button variant="contained" size='small' component="label" startIcon={<Iconify icon="ant-design:cloud-upload-outlined" />}>
                      Choose PDF File
                      <input hidden accept="application/pdf" onChange={(e)=>handlePdfFileChange(index ,e) } type="file" />
                  </Button>
                    </div>

                   <div className='flex' >
                   <Switch
                        checked={element?.paid}
                        onChange={(event)=>handleChangeSwitch(index,event)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        
                        />
                        <p>Paid File</p>
                   </div>
                   </div>
                   <p > {pdfFIleField[index]?.file?.name} </p>
                   </div>
                    )) }
                   <div style={{textAlign:'right'}} >
                   <Button size='small' variant='text' onClick={addFormFields} startIcon={<Iconify icon="ant-design:plus-outlined" />} > Add More File </Button>
                   </div>
                    
                   
                    <div className='' style={{paddingTop:20}} >
                  {fileUpload?.length > 0 && 
                   fileUpload?.map((value,index)=>(
                    <div key={index} className='uploaded-images-preview' >
                    {/* <img className='category-table-image' alt="product" src={URL.createObjectURL(value)} /> */}
                    <img className='category-table-image' alt="product" src={pdfImage} />
                    <p  >{value.name}</p>
                     <div className='remove-product-image-button' >
             <CancelIcon style={{color:'red',cursor:'pointer'}}  onClick={()=>handleRemoveImage(index)} />
                      </div> 
                    </div>
                   ))
                   }
                </div>
                </div>
{/* PDF FILE COL */}

{/* YOUTUBE VIDEO FILE COL */}


                <div className='file_upload_col'>
            <h4>Youtube Video Tutorials</h4>
                    {/* <FileUploadDesign fileUpload={fileUpload} handleFileUpload={handleFileUpload} /> */}
                    {youtubeFIleField.map((element,index)=>(
                      <div key={index} >
                    <div className='add_product_label_input' style={{width:'100%'}} >
                    <label htmlFor="">Youtube Video Title {index +1 }  </label>
                    {
                index ? 
                  
                  <Button variant='text' style={{marginLeft:"16px"}} onClick={() => removeFormFieldsYoutube(index)} > Remove </Button>
                : null
              }
                   {/* <TextField required fullWidth id="outlined-basic" name='subcategory'  placeholder="Subject Name" variant="outlined" /> */}
                 
                    <TextField  required fullWidth className='product_form_input' id="outlined-basic" value={element.youtube_video_title || ""} onChange={e => handleChangeYoutubeName(index, e)}  name="youtube_video_title"  placeholder="Enter Video Title" variant="outlined" />
                    <div className='add_product_label_input' style={{display:'flex',flexDirection:'column'}} >
                  <label htmlFor="">Youtube Video ID {index +1 } </label>
                  <TextField  required  className='product_form_input' id="outlined-basic" value={element.youtube_video_id || ""} onChange={e => handleChangeYoutubeName(index, e)}  name="youtube_video_id"  placeholder="Enter Youtube Video ID" variant="outlined" />
                  </div>
                    </div>
                 
                  <div style={{display:'flex',justifyContent:'flex-end'}} >
                  {/* <div className='flex' >
                   <Switch
                        checked={element?.paid}
                        onChange={(event)=>handleChangeSwitchYoutube(index,event)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        
                        />
                        <p>Paid File</p>
                   </div> */}
                  </div>
                   </div>
                    )) }
                   <div style={{textAlign:'right'}} >
                   <Button size='small' variant='text' onClick={addFormFieldsYoutube} startIcon={<Iconify icon="ant-design:plus-outlined" />} > Add More Videos </Button>
                   </div>
                    
                   
                    <div className='' style={{paddingTop:20}} >
                  {fileUpload?.length > 0 && 
                   fileUpload?.map((value,index)=>(
                    <div key={index} className='uploaded-images-preview' >
                    {/* <img className='category-table-image' alt="product" src={URL.createObjectURL(value)} /> */}
                    <img className='category-table-image' alt="product" src={pdfImage} />
                    <p  >{value.name}</p>
                     <div className='remove-product-image-button' >
             <CancelIcon style={{color:'red',cursor:'pointer'}}  onClick={()=>handleRemoveImage(index)} />
                      </div> 
                    </div>
                   ))
                   }
                </div>
                </div>
               {/* YOUTUBE VIDEO FILE COL */}
              
            </div>
        </div>
        </div>
    </>
  )
}

export default AddProducts