import { Navigate, useRoutes,BrowserRouter,Route,Routes } from 'react-router-dom';

import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import { UseContextState } from './global/GlobalContext/GlobalContext';

import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import Orders from './pages/Orders';
import Vendor from './pages/Vendor';
import Category from './pages/Category';
import ProtectedRoute from './utils/ProtectedRoute';
import PaymentForVendors from './pages/PaymentForVendors';
import Banners from './pages/Banners';
import Enquiry from './pages/Enquiry';
import CompetitiveContent  from "./pages/CompetitiveContent";
import CompetitiveCourse from "./pages/CompetitiveCourses";


// ----------------------------------------------------------------------

export default function Router() {
  const {authState} = UseContextState()

  console.log("AUTHSTATE",authState)
  const userState = authState.isAuthenticated


  // return useRoutes([
  //   {
  //     path: '/dashboard',
      
  //     element: <DashboardLayout />,
  //     children:[
  //       { path: 'app', element: <ProtectedRoute Component={DashboardApp} />    },
  //       { path: 'customer', element:<ProtectedRoute Component={User} /> },
  //       { path: 'products', element:<ProtectedRoute Component={Products} /> },
  //       { path: 'blog', element:<ProtectedRoute Component={Blog} /> },
  //       { path: 'orders', element:<ProtectedRoute Component={Orders} />},
  //       { path: 'vendor', element:<ProtectedRoute Component={Vendor} />},
  //       { path: 'category', element:<ProtectedRoute Component={Category} />}
  //     ],
  //   },
  //   {
  //     path: '/',
  //     element: <LogoOnlyLayout />,
  //     children: [
  //       { path: '/', element:  <ProtectedRoute Component={DashboardApp} />  },
  //       // { path: '/', element:  <Navigate to="/dashboard/app" />  },
  //       // { path: '/', element: <Navigate to="/login" /> },
  //       { path: 'login', element: <Login /> },
  //       { path: 'register', element: <Register /> },
  //       { path: '404', element: <NotFound /> },
  //       { path: '*', element: <Navigate to="/404" /> },
  //     ],
  //   },
  //   { path: '*', element: <Navigate to="/404" replace /> },
  // ]);
  // if(authState.isAuthenticated){
     return (
     
     <Routes>


     <Route element={<ProtectedRoute/>} >
 
     <Route exact path="/"  element={ <Navigate to='/dashboard/students' />    }  />
     {/* <Route exact path="/dashboard/app" element={ <DashboardLayout Component={<DashboardApp/>} /> } /> */}
    
     <Route path="/dashboard/students" element={ <DashboardLayout Component={<User/>} />} />
     <Route path="/dashboard/school-courses" element={ <DashboardLayout Component={<Category/>} />} />
     <Route path="/dashboard/school-course-content" element={ <DashboardLayout Component={<Products/>} />} />
     <Route path="/dashboard/competitive-courses" element={ <DashboardLayout Component={<CompetitiveCourse/>} />} />
     <Route path="/dashboard/competitive-course-content" element={ <DashboardLayout Component={<CompetitiveContent/>} />} />
     <Route path="/dashboard/banners" element={ <DashboardLayout Component={<Banners/>} />} />
     <Route path="/dashboard/orders" element={ <DashboardLayout Component={<Orders/>} />} />
     <Route path="/dashboard/enquiries" element={ <DashboardLayout Component={<Enquiry/>} />} />
   
      </Route>
       <Route exact path="/login" element={<LogoOnlyLayout Component={<Login/>} />   } />
       <Route exact path="*" element={<Navigate to="/login" />   } />
       
 
   </Routes>

    )

}
