// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: getIcon('eva:pie-chart-2-fill'),
  // },
  // {
  //   title: 'orders',
  //   path: '/dashboard/orders',
  //   icon: getIcon('bxs:box'),
  // },
  {
    title: 'Students',
    path: '/dashboard/students',
    icon: getIcon('eva:people-fill'),
  },
  // {
  //   title: 'vendors',
  //   path: '/dashboard/vendor',
  //   icon: getIcon('entypo:shop'),
  // },
 
  {
    title: 'School Courses',
    path: '/dashboard/school-courses',
    icon: getIcon('material-symbols:school-rounded'),
  },
  {
    title: 'School Course Content',
    path: '/dashboard/school-course-content',
    icon: getIcon('gridicons:institution'),
  },
  {
    title: 'Competitive Courses',
    path: '/dashboard/competitive-courses',
    icon: getIcon('mdi:book-plus'),
  },
  {
    title: 'Competitive Course Content',
    path: '/dashboard/competitive-course-content',
    icon: getIcon('ant-design:read-filled'),
  },
  {
    title: 'App Banners',
    path: '/dashboard/banners',
    icon: getIcon('eva:image-fill'),
  },
  // {
  //   title: 'Enquiries',
  //   path: '/dashboard/enquiries',
  //   icon: getIcon('mdi:message-bookmark'),
  // },

  
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon('eva:file-text-fill'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon('eva:lock-fill'),
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon('eva:person-add-fill'),
  // },
  

  
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
];

export default navConfig;
